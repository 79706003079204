import React from 'react'
import { TextField, ButtonGroup, Box } from '@mui/material'
import { getItem } from '../../services/storage'
import { EMAIL_STORAGE_KEY } from '../../config'
import { SubmitHandler, useForm } from 'react-hook-form'
import { AuthFormData, emailSignup } from '../../lib/auth'
import LoadingButton from '@mui/lab/LoadingButton'
import { Check } from '@styled-icons/material/Check'

interface SubscribeButtonProps extends Passthrough {
  label?: string
  onSignup?: () => void
}

export function SubscribeButton({
  label = 'Subscribe',
  onSignup,
  ...props
}: SubscribeButtonProps) {
  const [email, setEmail] = React.useState(getItem(EMAIL_STORAGE_KEY) || '')
  const [loading, setLoading] = React.useState(false)
  const { register, handleSubmit } = useForm({
    defaultValues: {
      email,
    },
  })

  const onSubmit: SubmitHandler<any> = (form: AuthFormData) => {
    setLoading(true)

    emailSignup(form.email)
      .then((redirect: boolean) => {
        setEmail(form.email)
        if (onSignup) onSignup()
      })
      .catch((e: Error) => {
        if (/email-already/.test(e.message)) {
          alert('Looks like you have already subscribed with this email.')
        } else {
          alert('Error: ' + e.message)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      width="100%"
      marginTop={2}
      {...props}
    >
      <ButtonGroup variant="contained" sx={{ width: '100%' }}>
        <TextField
          fullWidth
          required
          defaultValue={email}
          type="email"
          name="email"
          inputProps={{ ...register('email') }}
          placeholder="Email... "
        ></TextField>
        <LoadingButton
          loading={loading}
          type="submit"
          variant="contained"
          size="large"
        >
          {email ? <Check height="1.8rem" /> : label}
        </LoadingButton>
      </ButtonGroup>
    </Box>
  )
}
