import './services/bootstrap'
import './services/i18n'
import './assets/styles/animate.css'
import './assets/styles/reset.css'
import React from 'react'
import Routes from './Routes'
import { createRoot, hydrateRoot } from 'react-dom/client'

// import * as serviceWorker from './serviceWorker'

console.log('Version:', process.env.REACT_APP_VERSION)
const rootElement: HTMLElement | null = document.getElementById('root')

if (!rootElement) {
  throw new Error('No root element found')
} else {
  const root = createRoot(rootElement) // createRoot(container!) if you use TypeScript

  if (rootElement.hasChildNodes()) {
    hydrateRoot(rootElement, React.createElement(Routes))
  } else {
    root.render(React.createElement(Routes))
  }
}

// if (rootElement && rootElement.hasChildNodes()) {
//   hydrate(React.createElement(Routes), rootElement)
// } else {
//   render(React.createElement(Routes), rootElement)
// }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.register()
