import React from 'react'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import Error404 from './views/Error404'

import appTheme from './services/theme'
import LoadingIndicator from './components/LoadingIndicator'
import ScrollToTop from './components/ScrollToTop'
import Home from './views/Home'
// import { SubscriptionView } from './views/SubscriptionView'

const UserRoutes = React.lazy(() => import('./views/app/Routes'))
// const ResearchRoutes = React.lazy(
//   () => import('./views/research/ResearchRoutes')
// )
// const ChannelRoutes = React.lazy(() => import('./views/channel'))
// const ChannelsRoutes = React.lazy(() => import('./views/channels'))
const AuthRoutes = React.lazy(() => import('./views/auth'))

export default function Routes() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={appTheme}>
        <CssBaseline />
        <BrowserRouter>
          <ScrollToTop />
          <Switch>
            <Route path="/" component={Home} exact />
            {/* <Route path="/channels" component={load(ChannelsRoutes)} exact /> */}
            {/* <Route path="/channel(.*)" component={load(ChannelRoutes)} exact /> */}
            {/* <Route path="/pro" exact component={SubscriptionView} /> */}
            <Route path="/auth(.*)" component={load(AuthRoutes)} />
            {/* <Route path="/dir(.*)" component={load(ResearchRoutes)} /> */}
            <Route path="/(.*)" component={load(UserRoutes)} />
            <Route component={Error404} />
          </Switch>
        </BrowserRouter>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

function load(Component: React.LazyExoticComponent<any>) {
  return (props: object) => (
    <React.Suspense fallback={<LoadingIndicator />}>
      <Component {...props} />
    </React.Suspense>
  )
}
