import React from 'react'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'

// const textType = {
//   p: 'body1',
//   span: 'body1',
//   small: 'body2',
//   strong: 'body1',
// }

interface TextProps {
  el?: any
  raw?: boolean
  children: string
  [x:string]: any
}

export const Text = ({ el, raw, children, ...props }: TextProps) => {
  const { t } = useTranslation()

  const translate = (key: string) => t(key ? key.toString() : '')
  const textContent = raw ? children : translate(children)

  if (!el) {
    return <React.Fragment>{textContent}</React.Fragment>
  }

  return (
    <Typography
      {...props}
      variant={props.variant || el}
      component={props.component || el}
    >
      {textContent}
    </Typography>
  )
}

export default Text
