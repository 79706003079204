import React from 'react'
import { Container, Grid, Box, Typography, Paper, Avatar } from '@mui/material'
import cs from './Home.module.css'
import { SubscribeButton } from '../components/form/subscribeButton'
import feat2 from '../assets/img/features/research-data-room.jpg'
import feat1 from '../assets/img/features/market-reports.jpg'
import feat4 from '../assets/img/features/feat1.jpg'
import feat3 from '../assets/img/features/feat2.jpg'
import avatar from '../assets/img/eric.jpg'
// import feat4 from '../assets/img/features/web3-trends-HD.jpg'
import cx from 'classnames'
import { PerlinWaves } from '../components/perlinWaves/PerlinWaves'

export default function HomeView() {
  return (
    <>
      <Grid container></Grid>

      <Container maxWidth="lg">
        <Box
          textAlign="center"
          margin="auto"
          maxWidth={860}
          marginY={10}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Typography variant="h2" fontSize={32} color="#ced4da">
            Train and Manage
          </Typography>
          <Typography variant="h1" fontSize={48}>
            Your Neural Workforce
          </Typography>
          <Typography variant="h4" color="primary">
            Scale your research expertise with private deep learning pipelines
          </Typography>

          <SubscribeButton maxWidth={500} marginTop={3} />

          <Typography variant="body2" marginTop={1}>
            Subscribe to join our private beta
          </Typography>
        </Box>

        <Box
          component="section"
          display="flex"
          className={cs.featureDiagram}
          alignItems="center"
        >
          <Box>
            <Box className={cs.featureDialog} alignSelf="flex-start">
              <Typography className={cs.featureTitle} gutterBottom>
                1. <strong>Hire</strong>
              </Typography>
              <Typography>
                Select from a range of pretrained AI analysts, each fine-tuned
                to a provide expertise in a specific market domain.
              </Typography>
              <div className={cx(cs.borderCorner, cs.topRightCorner)} />
              <div className={cx(cs.borderCorner, cs.bottomLeftCorner)} />
            </Box>
            <Box
              maxWidth={360}
              marginTop={25}
              className={cs.featureDialog}
              alignSelf="flex-end"
            >
              <Typography className={cs.featureTitle} gutterBottom>
                3. <strong>Collaborate</strong>
              </Typography>
              <Typography>
                Share your analysis as interactive reports that allow anyone to
                make prompts and collaborate on insights seamlessly.
              </Typography>
              <div className={cx(cs.borderCorner, cs.bottomRightCorner)} />
              <div className={cx(cs.borderCorner, cs.topLeftCorner)} />
            </Box>
          </Box>

          <Box maxWidth={400} margin="auto" position="relative">
            {/* <img
              src={diagramImg}
              alt="learned-intelligence"
              className={cs.orb}
            /> */}
            <PerlinWaves />
          </Box>

          <Box marginTop={10} maxWidth={360} className={cs.featureDialog}>
            <Typography className={cs.featureTitle} gutterBottom>
              2. <strong>Train</strong>
            </Typography>
            <Typography>
              Align analyst's performance by configuring custom workflows
              through simple drag and drop interfaces.
            </Typography>
            <div className={cx(cs.borderCorner, cs.bottomRightCorner)} />
            <div className={cx(cs.borderCorner, cs.topLeftCorner)} />
          </Box>
        </Box>

        <Box maxWidth={650} margin="auto" marginY={10}>
          <Box component={Paper} padding={4}>
            <em>
              “It's an intelligence that's near our ability but that's not
              human. It discover things that we've never known and generate
              ideas that we as humans have never had.
              <strong> Think of them as savants</strong>”
            </em>
          </Box>
          <Box display="flex" marginTop={2} paddingX={4} alignItems="center">
            <Avatar src={avatar} alt="Eric Schmidt" />
            <Box marginLeft={2}>
              <Typography>Eric Schmidt</Typography>
              <Typography variant="body2">Former CEO of Google</Typography>
            </Box>
          </Box>
        </Box>

        <Typography variant="h1" textAlign="center" marginTop={15}>
          Use Cases
        </Typography>

        <Box
          component="section"
          display="flex"
          className={cs.features}
          marginTop={2}
          paddingBottom={20}
        >
          <Box className={cs.featureBox}>
            <Box component={Paper} className={cs.featureCard}>
              <Typography variant="h2">Expert Network Insights</Typography>
              <Typography className={cs.featureDescription}>
                Automatically idenitify key industry personnels and get alerts
                on expert industry insights.
              </Typography>
              <div
                className={cs.featureImg}
                style={{ backgroundImage: `url(${feat1})` }}
              />
            </Box>
          </Box>
          <Box className={cs.featureBox}>
            <Box component={Paper} className={cs.featureCard}>
              <Typography variant="h2">Extract Trends</Typography>
              <Typography className={cs.featureDescription}>
                Extract signals from any unstructured or semi-structured dataset
                to help you monitor industry trends.
                {/* Leverage unsupervised learning to explore and extract trends
                from your any unstructured dataset. */}
              </Typography>
              <div
                className={cs.featureImg}
                style={{ backgroundImage: `url(${feat3})` }}
              />
            </Box>
          </Box>
          <Box className={cs.featureBox}>
            <Box component={Paper} className={cs.featureCard}>
              <Typography variant="h2">Red Flag Detection</Typography>
              <Typography className={cs.featureDescription}>
                Create your own internal data room to create your own AI
                knowledge base.
              </Typography>
              <div
                className={cs.featureImg}
                style={{ backgroundImage: `url(${feat2})` }}
              />
            </Box>
          </Box>

          <Box className={cs.featureBox}>
            <Box component={Paper} className={cs.featureCard}>
              <Typography variant="h2">Semantic Search & Alerts</Typography>
              <Typography className={cs.featureDescription}>
                Run semantic queries on your data to get instant answers to your
                questions.
              </Typography>
              <div
                className={cs.featureImg}
                style={{ backgroundImage: `url(${feat4})` }}
              />
            </Box>
          </Box>
        </Box>

        <Box
          component="section"
          margin="auto"
          textAlign="center"
          marginY={10}
          maxWidth={800}
        >
          <Typography variant="h1">Join The Alpha Network</Typography>
          {/* <Typography variant="h1">Proof of Alpha</Typography> */}
          <Typography>
            There is an explosion of new asset classes and ideas and not enough
            in depth coverage on the emerging trends. We are building a network
            of early industry experts and analysts power the next generation of
            AI enabled market intelligence.
          </Typography>
        </Box>

        <Box
          component="section"
          margin="auto"
          textAlign="center"
          marginY={10}
          maxWidth={600}
        >
          {/*
          <Typography>
            Follow us on Twitter or Subscribe for our Weekly Update
          </Typography>
           */}
          <SubscribeButton />
        </Box>

        {/* <Box
          component="section"
          margin="auto"
          textAlign="center"
          marginY={10}
          maxWidth={550}
        >
          <Typography variant="h1">
            Rewarding Our
            <br /> Early Adopters
          </Typography>
        </Box> */}

        <Box
          component="footer"
          marginTop={15}
          marginBottom={3}
          color="grey"
          textAlign="center"
        >
          <a href="/terms.html">Terms of Service</a>
          <Box component="span" marginX={2}>
            &middot;
          </Box>
          <a href="/privacy.html">Privacy Policy</a>
        </Box>
      </Container>
    </>
  )
}
