import React from 'react'
import Grid from '@mui/material/Grid'

interface CenteredProps {
  container?: boolean
  horizontal?: boolean
  children?: React.ReactNode
  style?: object
  [x: string]: any
}

export default function Centered(props: CenteredProps) {
  const { children, container, horizontal, ...passThrough } = props
  const style =
    'container' in props
      ? { flex: '1 0 auto', ...props.style }
      : { ...props.style }
  const justify: any = 'horizontal' in props ? null : 'center'
  return (
    <Grid
      container
      className="animated fadeIn"
      justifyContent={justify}
      alignItems="center"
      direction="column"
      style={style}
      {...passThrough}
    >
      {children}
    </Grid>
  );
}
