import { createTheme } from '@mui/material'

// ref: https://material-ui.com/customization/default-theme/

const styles = window.getComputedStyle(document.documentElement)

function get(value, format) {
  return format
    ? format(styles.getPropertyValue(value))
    : styles.getPropertyValue(value)
}

const PRIMARY_COLOR = get('--primary') || '#3f51b5'
const SECONDARY_COLOR = get('--secondary') || '#ff4081'
const ERROR_COLOR = get('--danger') || '#ff9800'
const MAX_WIDTH = get('--breakpoint-xl', parseInt) || 1200
const INPUT_HEIGHT = get('--spacing', parseInt) * 6 || 48
const BOX_SHADOW_SM =
  get('--box-shadow-sm') ||
  '0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24)'
const BOX_SHADOW =
  get('--box-shadow') ||
  '0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24)'

// Box shadow 3D
// rgb(0 0 0 / 15%) 0px 2px 6px, rgb(0 0 0 / 15%) 0px -3px 0px inset

// ref: https://mui.com/customization/dark-mode/
export const palette = {
  primary: getColorPalette(PRIMARY_COLOR),
  secondary: getColorPalette(SECONDARY_COLOR),
  success: getColorPalette(SECONDARY_COLOR),
  warning: getColorPalette(ERROR_COLOR),
  purple: PRIMARY_COLOR,
  purpleBg: '#F3F2FF',
  green: SECONDARY_COLOR,
  greenBg: '#EBF9EC',
  orange: '#F58240',
  red: ERROR_COLOR,
  grey1: '#232B2B',
  grey2: '#8B9193',
  grey3: '#DDDEE0',
  grey4: '#F2F2F2',
  grey5: '#F6F6F6',
  background: {
    default: 'white',
    paper: get('--gray-bg'),
  },
  text: {
    secondary: get('--gray-text'),
  },
  mode: 'dark',
}

const shape = {
  borderRadius: parseInt(get('--border-radius')),
}
const defaultTheme = createTheme({})

export const theme = {
  shape,
  typography: {
    '@media (min-width:600px)': {
      htmlFontSize: get('--font-size-mobile', parseInt),
    },
    htmlFontSize: get('--font-size-root', parseInt),
    useNextVariants: true,
    fontFamily: get('--font-family-sans-serif'),
    lineHeight: 1.47,
    fontWeight: get('--font-weight-normal'),
    body1: {
      fontWeight: get('--font-weight-normal'),
      fontSize: '1rem',
      lineHeight: 1.47,
    },
    body2: {
      fontSize: get('--font-size-sm'),
      fontWeight: get('--font-weight-normal'),
      color: get('--gray-text'),
      lineHeight: 1.72,
    },
    subtitle1: {
      fontSize: get('--font-size-xs'),
      fontWeight: get('--font-weight-normal'),
      color: get('--gray-text'),
      lineHeight: 1.5,
    },
    h1: {
      fontWeight: get('--font-weight-bolder'),
      fontSize: '2.5rem',
      lineHeight: 1.4,
      // textTransform: 'capitalize',
      letterSpacing: 1,
    },
    h2: {
      fontFamily: get('--font-family-mono'),
      fontWeight: get('--font-weight-bolder'),
      fontSize: '1.5rem',
      lineHeight: 1.72,
      // textTransform: 'capitalize',
    },
    h3: {
      fontFamily: get('--font-family-mono'),
      fontWeight: get('--font-weight-bolder'),
      fontSize: '1.25rem',
      lineHeight: 1.72,
      // textTransform: 'capitalize',
    },
    h4: {
      fontFamily: get('--font-family-mono'),
      fontWeight: get('--font-weight-bold'),
      fontSize: '1.15rem',
      lineHeight: 1.47,
      // textTransform: 'capitalize',
    },
    h5: {
      fontFamily: get('--font-family-mono'),
      fontWeight: get('--font-weight-bold'),
      fontSize: '1rem',
      lineHeight: 1.47,
    },
    h6: {
      fontWeight: get('--font-weight-bold'),
      fontSize: '0.8rem',
      lineHeight: 1,
    },
    caption: {
      color: palette.grey2,
    },
  },
  palette,

  shadows: ['none', BOX_SHADOW_SM, ...Array(23).fill(BOX_SHADOW)],

  components: {
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiInputLabel: {
      shrink: true,
    },
    MuiTypography: {
      h6: {
        fontWeight: 'bold',
      },
      body2: {
        color: palette.grey2,
      },
      colorTextSecondary: {
        color: 'rgba(0, 0, 0, 0.33)',
      },
    },
    MuiContainer: {
      maxWidthLg: {
        maxWidth: `1180px !important`,
      },
    },

    // -- Buttons -- //
    MuiButton: {
      root: {
        fontStyle: 'initial',
        padding: `${defaultTheme.spacing(1.5)}px ${defaultTheme.spacing(
          2.5
        )}px`,
        fontSize: get('--font-size-base'),
        lineHeight: 1.2,
        textTransform: 'capitalize',
        transition: 'all .1s ease',
        fontWeight: 'bold',
      },
      containedSizeSmall: {
        padding: `${defaultTheme.spacing(1)}px ${defaultTheme.spacing(2)}px`,
      },
      containedSizeLarge: {
        fontSize: get('--font-size-large'),
        padding: `${defaultTheme.spacing(2)}px ${defaultTheme.spacing(3)}px`,
      },
      contained: {
        boxShadow: 'none',
        // color: palette.grey2,
      },
      containedSecondary: {
        backgroundColor: 'black',
        color: 'white',
      },
      outlined: {
        padding: '13px 18px 15px',
      },
    },
    MuiFab: {
      extended: {
        textTransform: 'capitalize',
        fontSize: get('--font-size-base'),
        fontWeight: get('--font-weight-normal', parseInt),
        height: defaultTheme.spacing(5),
      },
    },
    MuiSvgIcon: {
      root: {
        fontSize: '1.2rem',
      },
    },
    MuiIconButton: {
      root: {
        '&:hover': {
          backgroundColor: 'none',
        },
      },
    },

    // --- Avatar --- //
    MuiAvatarGroup: {
      avatar: {
        border: '1px solid white',
        marginLeft: -12,
      },
    },

    // --- Structural --- //
    MuiGrid: {
      container: {
        maxWidth: MAX_WIDTH,
        width: '100%',
        margin: 'auto',
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: 'rgba(222,222,222,0.5)',
      },
    },

    // --- Tabs --- //
    MuiTab: {
      root: {
        padding: '6px 18px',
        textTransform: 'capitalize',
        minWidth: '0 !important',
      },
    },
    MuiTabs: {
      button: {
        '&.Mui-selected': {
          color: 'white',
        },
      },
      root: {
        color: defaultTheme.palette.text.primary,
      },
    },

    // --- Dialog --- //
    // MuiModal: { // TODO: remove, doesn't work in Mui V5
    //   root: {
    //     display: 'flex',
    //     alignItems: 'center',
    //     justifyContent: 'center',
    //   },
    // },

    // --- Form Elements --- //
    MuiInput: {
      disableUnderline: true,

      root: {
        textTransform: 'capitalize',
        fontSize: get('--font-size-root', parseInt),
        marginBottom: defaultTheme.spacing(1),
        borderRadius: shape.borderRadius,
        backgroundColor: palette.grey4,
        '&:focus': {
          // boxShadow: `0px 0px 0px 2px ${palette.primary.main}`,
          background: defaultTheme.palette.common.white,
        },
      },
      input: {
        minHeight: INPUT_HEIGHT,
        padding: '10px 16px',
        boxSizing: 'border-box',
        transition: 'all .1s ease',
      },
    },
    MuiInputBase: {
      adornedStart: {
        paddingLeft: defaultTheme.spacing(1),
      },
      adornedEnd: {
        paddingRight: defaultTheme.spacing(1),
      },
      inputAdornedStart: {
        paddingLeft: `${defaultTheme.spacing(1)}px !important`,
      },
      inputAdornedEnd: {
        paddingRight: `0px !important`,
      },
    },
    MuiSelect: {
      select: {
        lineHeight: '30px',
        width: '100%',
        '&:focus': {
          borderRadius: shape.borderRadius,
        },
      },
      icon: {
        fontSize: '1.25rem',
        top: 'calc(50% - 10px)',
        right: 3,
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 'none',
        marginRight: '1rem',
      },
    },
    MuiListItem: {
      root: {
        textTransform: 'capitalize',
        '&.Mui-selected': {
          backgroundColor: palette.primary.main,
          color: 'white',
        },
        '&.Mui-selected:hover': {
          backgroundColor: palette.primary.main,
          color: 'white',
        },
      },
      button: {
        '&:hover': {
          backgroundColor: palette.primary.main,
          color: 'white',
        },
      },
    },
    MuiMenuItem: {
      root: {
        height: INPUT_HEIGHT,
        '&.Mui-disabled': {
          display: 'none',
        },
      },
    },
    MuiNativeSelect: {
      select: {
        width: '100%',
      },
    },
    MuiFormControl: {
      root: {
        width: '100%',
      },
    },
    MuiFormLabel: {
      root: {
        color: palette.grey1,
        marginBottom: '1rem',
        fontWeight: 500,
      },
    },
    // MuiInputLabel: {
    //   shrink: {
    //     transform: 'translate(0, -3px) scale(0.9)',
    //   },
    // },
    MuiInputAdornment: {
      positionStart: {
        marginRight: -defaultTheme.spacing(1),
        marginLeft: defaultTheme.spacing(1),
      },
    },
    MuiSlider: {
      thumb: {
        border: '2px solid',
        backgroundColor: 'white',
        width: defaultTheme.spacing(3),
        height: defaultTheme.spacing(3),
        marginTop: -defaultTheme.spacing(),
      },
      valueLabel: {
        left: 'auto',
      },
      track: {
        height: 5,
      },
      rail: {
        height: 5,
      },
      mark: {
        height: 5,
      },
    },
  },

  // -- Animations --- //
  transitions: {
    duration: {
      shortest: 75,
      shorter: 100,
      short: 150,
      standard: 200,
      complex: 275,
    },
  },
}

function getColorPalette(col) {
  return {
    main: col,
  }
}

console.log('theme', createTheme(theme).breakpoints)

export default createTheme(theme)
