const en = {
  w: {
    not_found: 'not found',
    home: 'home',
  },

  p: {
    title: '',
  },

  landing: {
    title: 'Alpha.re',
    welcome: 'Tracking Market Predictions From Top Investors and Whales',
  },

  legal: {
    copyright: '© 2022',
  },

  forms: {},
}

export default en
