import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Centered from './layout/Centered'

export default function LoadingIndicator() {
  return (
    <Centered container>
      <CircularProgress />
    </Centered>
  )
}
