import { initializeApp } from 'firebase/app'
import {
  getAuth,
  createUserWithEmailAndPassword,
  // signInWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  isSignInWithEmailLink,
  sendEmailVerification,
  sendSignInLinkToEmail,
  signInWithEmailLink,
  GoogleAuthProvider,
  UserCredential,
  signInWithPopup,
  signOut,
} from 'firebase/auth'
import axios from 'axios'
import { EMAIL_STORAGE_KEY } from '../../config/constants'
import { getItem, setItem } from '../../services/storage'

const firebaseConfig = {
  apiKey: 'AIzaSyBBVZVq_arOL-EpGiLXBjmvdZs7_butSIs',
  authDomain: 'helm-5dc86.firebaseapp.com',
  databaseURL: 'https://helm-5dc86.firebaseio.com',
  projectId: 'helm-5dc86',
  storageBucket: 'helm-5dc86.appspot.com',
  messagingSenderId: '704796156111',
  appId: '1:704796156111:web:63d069f50688be910b17ba',
  measurementId: 'G-8E6Y46TZ5R',
}
const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
// firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE)

export interface AuthFormData {
  email: string
  password: string
}
// ref: https://firebase.google.com/docs/auth/admin/manage-cookies

export async function login({
  email,
  password,
}: AuthFormData): Promise<boolean> {
  // google.com, password
  const signinMethods = await fetchSignInMethodsForEmail(auth, email)
  if (!signinMethods.length) {
    const proceedToSignup = window.confirm(
      `User not found: do want to signup with: ${email}?`
    )
    if (proceedToSignup) {
      await emailSignup(email)
      // TODO redirect to app with new credentials
      return true
    }
  } else {
    await emailLogin(email)
  }
  return false
}

export function emailSignup(email: string) {
  return signup({ email, password: Math.random().toString(36).slice(2) })
}

export function signup({ email, password }: AuthFormData): Promise<any> {
  return createUserWithEmailAndPassword(auth, email, password)
    .then((credentials) => {
      setItem(EMAIL_STORAGE_KEY, email)
      sendEmailVerification(credentials.user)
      // alert('Please check your email for a verification link.')
      return credentials
    })
    .then(handleSession)
    .then(endSession)
}

// ref: https://firebase.google.com/docs/auth/web/email-link-auth
const actionCodeSettings = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be in the authorized domains list in the Firebase Console.
  url: (process.env.REACT_APP_HOST || 'https://www.alpha.re') + '/auth/login',
  // This must be true.
  handleCodeInApp: true,
}

export function emailLogin(email: string): Promise<any> {
  return sendSignInLinkToEmail(auth, email, actionCodeSettings)
    .then(() => {
      // The link was successfully sent. Inform the user.
      // Save the email locally so you don't need to ask the user for it again
      // if they open the link on the same device.
      setItem(EMAIL_STORAGE_KEY, email)

      // prompt user to check their email for a login link
      alert(`Please check ${email} for a login link.`)
    })
    .catch((e) => {
      console.error('error', e)
    })
}

export async function handlEmailLoginLink(
  href = window.location.href
): Promise<boolean> {
  if (isSignInWithEmailLink(auth, href)) {
    let email = getItem<string>(EMAIL_STORAGE_KEY) || ''
    if (!email) {
      email = window.prompt('Please provide your email for confirmation') || ''
    }
    return signInWithEmailLink(auth, email, window.location.href)
      .then(handleSession)
      .then(() => true)
      .catch((error) => {
        console.error('[LoginLink]', error)
        return false
        // Some error occurred, you can inspect the code: error.code
        // Common errors could be invalid email and invalid or expired OTPs.
      })
  }
  return false
}

function handleSession({ user }: UserCredential) {
  if (user) {
    // Get the user's ID token as it is needed to exchange for a session cookie.
    return user.getIdToken().then((idToken: string) => {
      // Session login endpoint is queried and the session cookie is set.
      const query = new URLSearchParams(window.location.search)

      // TODO implement...
      // const csrfToken = getCookie('csrfToken')
      return axios.post(
        `${process.env.REACT_APP_GRAPHQL_HOST}/session`,
        {
          idToken,
          activation: query.get('activation'),
          // csrfToken
        },
        { withCredentials: true }
      )
    })
  }
  return
}

const googleProvider = new GoogleAuthProvider()
export function loginWithGoogle() {
  signInWithPopup(auth, googleProvider).then(handleSession).then(endSession)
}

function endSession(resp: any) {
  // A page redirect would suffice as the persistence is set to NONE.
  signOut(auth)
  return resp
}
