import React from 'react'
import Button from '@mui/material/Button'
import Centered from '../components/layout/Centered'
import { useHistory } from 'react-router-dom'
import Text from '../components/Text'

export default function NotFound() {
  const history = useHistory()
  return (
    <Centered container>
      <Text raw>404</Text>
      <Text el="h3">w.not_found</Text>
      <Button variant="outlined" onClick={() => history.push('/')}>
        <Text>w.home</Text>
      </Button>
    </Centered>
  )
}
